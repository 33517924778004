
import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocation, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

const Contact = () => {
  return (
    <div>
      <Navbar />
      {/* contact start */}
      <div className="max-w-screen font-poppins overflow-hidden ">
        <section class="relative z-10 bg-[#e0e0e0]  py-20  sm:px-20 sm:py-40 ">
          <div class="container mx-auto">
            <div class="-mx-4 flex flex-wrap lg:justify-between">
              <div class="w-full px-4 lg:w-1/2 xl:w-6/12">
                <div class="mb-12 max-w-[570px] lg:mb-0 pl-4">
                  <span class="text-customOrange mb-4 block text-base font-semibold">
                    Contact Us
                  </span>
                  <h2 class="text-black mb-6 text-[32px] font-bold uppercase sm:text-[40px] lg:text-[36px] xl:text-[40px]">
                  Get Started Today
                  </h2>
                  <p class="text-gray-700 mb-9 text-base leading-relaxed">
                  Don't hesitate to reach out to us today to start your website project!
                   Our team is dedicated to delivering high-quality and custom solutions 
                   that meet your business needs. Let us help you elevate your online presence 
                   and achieve your goals. Contact us for a free consultation and let's get started! 
                    So, why wait? Get in touch with us now! 
                  </p>
                  <div class="mb-8 flex w-full max-w-[370px]">
                    <div class="bg-blue-400/20 text-primary mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded  sm:h-[70px] sm:max-w-[70px]">
                    <FontAwesomeIcon icon={faLocation} style={{ fontSize: '30px' }} />
                    </div>
                    <div class="w-full">
                      <h4 class="text-dark mb-1 text-xl font-bold">
                        Our Location
                      </h4>
                      <p class="text-body-color text-base">
                        7901 4th St N Ste St. Petersburg, FL 33702
                      </p>
                    </div>
                  </div>
                  <div class="mb-8 flex w-full max-w-[370px]">
                    <div class="bg-blue-400/20 text-primary mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded  sm:h-[70px] sm:max-w-[70px]">
                    <FontAwesomeIcon icon={faPhone} style={{ fontSize: '30px' }}/>
                    </div>
                    <div class="w-full">
                      <h4 class="text-dark mb-1 text-xl font-bold">
                        Phone Number
                      </h4>
                      <p class="text-body-color text-base">
                        +1 (863) 225-1357
                      </p>
                    </div>
                  </div>
                  <div class="mb-8 flex w-full max-w-[370px]">
                    <div class="bg-blue-400/20 text-primary mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded  sm:h-[70px] sm:max-w-[70px]">
                    <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: '30px' }} />
                    </div>
                    <div class="w-full">
                      <h4 class="text-dark mb-1 text-xl font-bold">
                        Email Address
                      </h4>
                      <p class="text-body-color text-base">
                        eowens@gendesigntech.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full px-4 lg:w-1/2 xl:w-5/12">
                <div class="relative rounded-lg bg-white p-8 shadow-lg sm:p-12">
                  <form>
                    <div class="mb-6">
                      <input
                        type="text"
                        placeholder="Your Name"
                        class="text-body-color border-[f0f0f0] focus:border-primary w-full rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"
                      />
                    </div>
                    <div class="mb-6">
                      <input
                        type="email"
                        placeholder="Your Email"
                        class="text-body-color border-[f0f0f0] focus:border-primary w-full rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"
                      />
                    </div>
                    <div class="mb-6">
                      <input
                        type="text"
                        placeholder="Your Phone"
                        class="text-body-color border-[f0f0f0] focus:border-primary w-full rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"
                      />
                    </div>
                    <div class="mb-6">
                      <textarea
                        rows="6"
                        placeholder="Your Message"
                        class="text-body-color border-[f0f0f0] focus:border-primary w-full resize-none rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"
                      ></textarea>
                    </div>
                    <div>
                      <button
                        type="submit"
                        class="bg-customOrange border-primary w-full rounded border p-3 text-white transition hover:bg-opacity-90"
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                  <div>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* contact start end */}
      {/* brands */}
      {/* <section class="bg-white py-20 lg:py-[120px] sm:h-20 ">
        <div class="container mx-auto sm:-mt-10">
          <div class="-mx-4 flex flex-wrap">
            <div class="w-full px-4">
              <div class="flex flex-wrap items-center justify-center">
                <a
                  href="javascript:void(0)"
                  class="mx-4 flex w-[150px] items-center justify-center py-5 2xl:w-[180px]"
                >
                  <img
                    src="https://cdn.tailgrids.com/2.0/image/assets/images/brands/graygrids.svg"
                    alt="image"
                    class="h-10 w-full"
                  />
                </a>
                <a
                  href="javascript:void(0)"
                  class="mx-4 flex w-[150px] items-center justify-center py-5 2xl:w-[180px]"
                >
                  <img
                    src="https://cdn.tailgrids.com/2.0/image/assets/images/brands/lineicons.svg"
                    alt="image"
                    class="h-10 w-full"
                  />
                </a>
                <a
                  href="javascript:void(0)"
                  class="mx-4 flex w-[150px] items-center justify-center py-5 2xl:w-[180px]"
                >
                  <img
                    src="https://cdn.tailgrids.com/2.0/image/assets/images/brands/uideck.svg"
                    alt="image"
                    class="h-10 w-full"
                  />
                </a>
                <a
                  href="javascript:void(0)"
                  class="mx-4 flex w-[150px] items-center justify-center py-5 2xl:w-[180px]"
                >
                  <img
                    src="https://cdn.tailgrids.com/2.0/image/assets/images/brands/ayroui.svg"
                    alt="image"
                    class="h-10 w-full"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* brands end  */}
      {/* recent news blog */}
      {/* <section class="pt-20 pb-10 lg:pt-[120px] lg:pb-20 bg-gradient-to-tl from-blue-100">
        <div class="container mx-auto">
          <div class="-mx-4 flex flex-wrap justify-center">
            <div class="w-full px-4">
              <div class="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
                <span class="text-primary mb-2 block text-lg font-semibold">
                  Our Blogs
                </span>
                <h2 class="text-dark mb-4 text-3xl font-bold sm:text-4xl md:text-[40px]">
                  Our Recent News
                </h2>
                <p class="text-body-color text-base">
                  There are many variations of passages of Lorem Ipsum available
                  but the majority have suffered alteration in some form.
                </p>
              </div>
            </div>
          </div>
          <div class="-mx-4 flex flex-wrap">
            <div class="w-full px-4 md:w-1/2 lg:w-1/3">
              <div class="mx-auto mb-10 max-w-[370px]">
                <div class="mb-8 overflow-hidden rounded">
                  <img
                    src="https://cdn.tailgrids.com/2.0/image/application/images/blogs/blog-01/image-01.jpg"
                    alt="image"
                    class="w-full"
                  />
                </div>
                <div>
                  <span class="bg-blue-400/80 mb-5 inline-block rounded py-1 px-4 text-center text-xs font-semibold leading-loose text-white">
                    Dec 22, 2023
                  </span>
                  <h3>
                    <a
                      href="javascript:void(0)"
                      class="text-dark hover:text-primary mb-4 inline-block text-xl font-semibold sm:text-2xl lg:text-xl xl:text-2xl"
                    >
                      Meet AutoManage, the best AI management tools
                    </a>
                  </h3>
                  <p class="text-body-color text-base">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full px-4 md:w-1/2 lg:w-1/3">
              <div class="mx-auto mb-10 max-w-[370px]">
                <div class="mb-8 overflow-hidden rounded">
                  <img
                    src="https://cdn.tailgrids.com/2.0/image/application/images/blogs/blog-01/image-02.jpg"
                    alt="image"
                    class="w-full"
                  />
                </div>
                <div>
                  <span class="bg-blue-400/80 mb-5 inline-block rounded py-1 px-4 text-center text-xs font-semibold leading-loose text-white">
                    Mar 15, 2023
                  </span>
                  <h3>
                    <a
                      href="javascript:void(0)"
                      class="text-dark hover:text-primary mb-4 inline-block text-xl font-semibold sm:text-2xl lg:text-xl xl:text-2xl"
                    >
                      How to earn more money as a wellness coach
                    </a>
                  </h3>
                  <p class="text-body-color text-base">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full px-4 md:w-1/2 lg:w-1/3">
              <div class="mx-auto mb-10 max-w-[370px]">
                <div class="mb-8 overflow-hidden rounded">
                  <img
                    src="https://cdn.tailgrids.com/2.0/image/application/images/blogs/blog-01/image-03.jpg"
                    alt="image"
                    class="w-full"
                  />
                </div>
                <div>
                  <span class="bg-blue-400/80 mb-5 inline-block rounded py-1 px-4 text-center text-xs font-semibold leading-loose text-white">
                    Jan 05, 2023
                  </span>
                  <h3>
                    <a
                      href="javascript:void(0)"
                      class="text-dark hover:text-primary mb-4 inline-block text-xl font-semibold sm:text-2xl lg:text-xl xl:text-2xl"
                    >
                      The no-fuss guide to upselling and cross selling
                    </a>
                  </h3>
                  <p class="text-body-color text-base">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* recent news blog end */}
      <Footer />
    </div>
  );
};

export default Contact;
