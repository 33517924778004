import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import CallToAction from '../../Components/CallToAction/CallToAction';
import Hero from '../../Components/Hero/Hero';
import portfolioimg from '../../assests/portfolioimg.png';

const Portfolio = () => {
    const projects = [1, 2, 3, 4, 5, 6]; // Replace with your actual projects data
    const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

    useEffect(() => {
        setTimeout(() => {
            setAnimateCard({ y: 0, opacity: 1 });
        }, 500);
    }, []);

    return (
        <>
            <Navbar />
            <div className="max-w-screen overflow-hidden font-poppins">
                <Hero
                    title="Showcasing Innovation"
                    subtitle="Our Work Speaks for Itself"
                    description="From small businesses to large enterprises, our digital solutions have helped clients across industries achieve their goals and grow their online presence."
                    image={portfolioimg}
                />
                <div className="container mx-auto">
                    <div className="flex justify-center mt-24">
                    <h1 tabIndex={0} className="hidden sm:flex border-y-2 py-4 border-y-indigo-900/30 text-3xl sm:text-5xl font-extrabold justify-center text-center leading-10 text-gray-800 sm:w-[70%] w-[95%] pt-4">
                        Our Clients Projects
                    </h1>
                </div>
                
                <div className="flex justify-center mt-20 mb-20">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 px-4 sm:px-6 lg:px-8 w-full max-w-screen-xl">
                        {projects.map((project, index) => (
                            <motion.div
                                key={index}
                                animate={animateCard}
                                whileHover={{ scale: 1.05, boxShadow: "0px 0px 8px rgb(0,0,0,0.1)" }}
                                transition={{ duration: 0.3 }}
                                className="cursor-pointer w-100 h-110 "
                            >
                                <div className="p-4 bg-white rounded shadow-md hover:shadow-lg transition-shadow duration-300 h-full flex flex-col">
                                    <img className="w-full h-90 object-cover rounded-t" src="https://via.placeholder.com/150" alt={`Project ${project}`} />
                                    <h2 className="mt-2 text-lg sm:text-xl font-semibold">Project {project}</h2>
                                    <p className="text-gray-600">Project description...</p>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </div>
                </div>
                <CallToAction
                    title="Let's have a conversation about your goals and how we can help you achieve them."
                    subtitle="Ready to Start Your Next Project? Let's Chat!"
                />
            </div>
            <Footer />
        </>
    );
}

export default Portfolio;
