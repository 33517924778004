import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Hero from '../../Components/Hero/Hero'
import Features from '../../Features/Features';
// import CardComponent from '../../Components/Card/CardComponent';
import CallToAction from '../../Components/CallToAction/CallToAction';
// import Review from '../../Components/Review/Review';
import Footer from '../../Components/Footer/Footer';
import img from '../../assests/hero.png'

const Homepage = () => {
    return (
        <div>
            <Navbar />
            <Hero
                title="Get Noticed with Our"
                subtitle="Top-Rated Web Design Services"
                description="From Concept to Launch: Comprehensive Custom Web Development Services"
                image={img}
            />
            <Features />
            {/* <CardComponent /> */}
            <CallToAction
              title="Contact us for a free consultation and let's get started !"
              subtitle="Let us help you showcase your work and achieve your goals."
            />
            {/* <Review /> */}
            <Footer />
        </div>
    );
}

export default Homepage;
