import React, { useEffect, useRef } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { useAnimation, useInView, motion } from "framer-motion";
import aboutimg from '../../assests/aboutimg.jpg'
import aboutimg2 from '../../assests/aboutimg2.JPG'

const About = () => {
    const ref = useRef();
    const isInView = useInView(ref);
    const mainControls = useAnimation();
    useEffect(() => {
      if (isInView) {
        mainControls.start("visible");
      }
    }, [isInView, mainControls]);

    return (
        <div className="font-poppins">
            <Navbar />
            <div className="max-w-screen  overflow-hidden">
                <div className="bg-gradient-to-b  from-gray-100/10 to-gray-200/50 pt-20">
                    <div className="mt-20 container mx-auto">
                        <motion.div
                            ref={ref}
                            variants={{
                                hidden: { opacity: 0, y: -75 },
                                visible: { opacity: 1, y: 0 },
                            }}
                            initial="hidden"
                            animate={mainControls}
                            transition={{ duration: 0.5, delay: 0.25 }}
                            className="flex justify-center text-center"
                        >
                            <h1 className="text-black font-extrabold text-3xl md:text-4xl lg:text-5xl">
                                Who We Are
                            </h1>
                        </motion.div>
                        <div className="flex flex-col  md:flex-row justify-between items-center mt-10 md:mt-20 px-4 md:px-20">
                            <motion.div
                                ref={ref}
                                variants={{
                                    hidden: { opacity: 0, x: -75 },
                                    visible: { opacity: 1, x: 0 },
                                }}
                                initial="hidden"
                                animate={mainControls}
                                transition={{ duration: 0.5, delay: 0.25 }}
                                className="w-full md:w-1/2 mb-10 md:mb-8"
                            >
                                <img
                                    src={aboutimg}
                                    alt="imageAbout"
                                    className="rounded-2xl mx-auto shadow-lg transition-transform transform hover:-translate-y-2 duration-300"
                                />
                            </motion.div>
                            <motion.div
                                ref={ref}
                                variants={{
                                    hidden: { opacity: 0, x: 75 },
                                    visible: { opacity: 1, x: 0 },
                                }}
                                initial="hidden"
                                animate={mainControls}
                                transition={{ duration: 0.5, delay: 0.25 }}
                                className="text-center md:text-right"
                            >
                                <h2 className="text-xl md:text-3xl lg:text-2xl font-extrabold text-black mb-10 md:mb-8">
                                     
                                </h2>
                                <h1 className="text-black mb-3 font-bold leading-snug sm:text-[42px] lg:text-[40px] xl:text-[32px]">
                                Expert Web Development Agency: 
                                  <br />
                                  <span className="sm:text-6xl text-4xl">
                                  Your Online Success
                                  </span>
                                </h1>
                                
                            </motion.div>
                        </div>
                        <motion.div
                            ref={ref}
                            variants={{
                                hidden: { opacity: 0 },
                                visible: { opacity: 1 },
                            }}
                            initial="hidden"
                            animate={mainControls}
                            transition={{ duration: 0.5, delay: 0.25 }}
                            className="text-center md:text-left px-4 md:px-20 mt-16 pb-16"
                        >
                            <h3 className="text-lg md:text-2xl lg:text-3xl font-semibold mb-4">
                                About Us:
                            </h3>
                            <p className="text-base md:text-lg lg:text-xl font-medium text-gray-700">
                                At GenDesign, we pride ourselves on delivering a comprehensive suite of digital services designed to elevate your online presence and drive business growth. Our team of experts is dedicated to providing high-quality web development solutions, ensuring your website is not only visually appealing but also highly functional and user-friendly. We specialize in creating responsive, performance-optimized websites that cater to your specific business needs. From custom web applications to e-commerce platforms, our web development services are aimed at delivering exceptional results that exceed our clients' expectations. With our customer-centric approach, we strive to build long-term partnerships with each of our clients, helping them achieve success in the digital landscape.
                            </p>
                        </motion.div>
                    </div>
                </div>
                <div className="bg-white-100 px-10 py-16">
                    <div className="container mx-auto">
                        <div className="text-center mb-10">
                            <p className="text-gray-500 text-lg font-normal pb-3">
                                Behind The Scenes
                            </p>
                            <h1 className="text-3xl md:text-4xl font-extrabold text-gray-800">
                                Meet The Owner
                            </h1>
                        </div>
                        <div className="flex flex-col md:flex-row justify-center items-center">
                            <div className="w-full md:w-1/2 mb-10 md:mb-0 md:mr-20">
                                <img
                                    src={aboutimg2}
                                    alt="owner"
                                    className="w-full md:w-3/4 h-auto rounded-2xl mx-auto shadow-lg transition-transform transform hover:-translate-y-2 duration-300"
                                />
                            </div>
                            <div className="w-full md:w-1/2 mt-10 md:mt-0 text-center md:text-left">
                                <h2 className="text-2xl md:text-3xl font-extrabold text-gray-800 pb-5">
                                    Eugene Owens
                                </h2>
                                <p className="text-gray-500 text-lg font-normal pb-5">
                                    Founder & CEO
                                </p>
                                <p className="text-gray-500 text-lg font-normal">
                                    Choose GenDesign for our commitment to excellence, personalized service, and a track record of delivering results. We are passionate about helping businesses succeed in the digital landscape and are here to support you every step of the way.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default About;
