import React from 'react'
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa'
import { FaSquareXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <div className="overflow-x-hidden max-w-screen font-poppins">
      {/* footer top section */}
      <div className="sm:flex justify-between py-10 px-8 grid bg-customgray">
        <div className="text-white">
          <h1 className="w-full text-3xl font-bold cursor-pointer text-white">
            Discover Your Online Potential   
          </h1>
          <p className="py-4">
            We understand that every business is unique, and our services are designed to be flexible and tailored to meet your specific goals. Let us help you leverage the full potential of your digital footprint with our comprehensive suite of services.
          </p>
          <div className="flex md:w-[25%] justify-between my-6 text-blue-200">
            {/* social media icons */}
            <FaFacebookSquare size={30} className="cursor-pointer " />
            {/* <FaDribbbleSquare size={30} className="cursor-pointer " /> */}
            <FaInstagram size={30} className="cursor-pointer " />
            {/* <FaGithubSquare size={30} className="cursor-pointer " /> */}
            <FaSquareXTwitter size={30} className="cursor-pointer " />
          </div>
        </div>
        <div className="text-white sm:w-[900px] sm:ml-[18%] sm:mr-[12%] sm:pt-0">
          <div>
            <h1 className="sm:w-[30rem] md:text-4xl sm:text-3xl text-center text-2xl font-bold py-2">
            Subscribe to Our Newsletter for the Latest Updates!
            </h1>
            <p className=" text-center pb-4">
            Don't miss out on the opportunity to stay ahead of the game and subscribe now!
            </p>
          </div>
          <div className="my-4">
            <div className="justify-center flex">
              <input
                className="placeholder:pl-2 flex sm:mr-[-50px] h-10 sm:w-[50%]  sm:h-[35px] mx-auto rounded-md text-black"
                type="email"
                placeholder="Enter Your E-Mail"
              />
              <button className="bg-customOrange mx-auto w-28 hover:bg-blue-300/10 rounded-md font-medium">
                Sign Up
              </button>
            </div>
            {/* <p className="text-sm mx-10 flex-col text-center pt-4">
              Wee care about the protection of your data. Read our{" "}
              <span className="underline cursor-pointer text-blue-400">
                Privacy Policy
              </span>
            </p> */}
          </div>
        </div>
      </div>
      {/* footer top section end */}
      <div className="sm:mx-auto pt-10 pb-8 sm:grid flex-row lg:grid-cols-3 w-screen gap-8 text-white bg-customOrange ">
        <div className="lg:col-span-3 sm:mr-20  flex justify-between max-w-screen sm:ml-20 ml-[12px] mb-4 sm:mb-0 ">
          <div className="pr-4">
            <h6 className="font-medium text-black">Main Pages</h6>
            <ul>
              <li className="py-2 text-sm cursor-pointer font-semibold">
                <a href="/services">Services</a>
              </li>
              <li className="py-2 text-sm cursor-pointer font-semibold">
                <a href="/about">About</a>
              </li>
              <li className="py-2 text-sm cursor-pointer font-semibold">
                <a href="/portfolio">Portfolio</a>
              </li>
              <li className="py-2 text-sm cursor-pointer font-semibold">
                <a href="/contact">Contact</a>
              </li>
            </ul>
          </div>
          <div className="pr-4">
            <h6 className="font-medium text-black">Services</h6>
            <ul>
              <li className="py-2 text-sm cursor-pointer font-semibold">
                <a href="/services">Frontend Development</a>
              </li>
              <li className="py-2 text-sm cursor-pointer font-semibold">
                <a href="/services">Backend Development</a>
              </li>
              <li className="py-2 text-sm cursor-pointer font-semibold">
                <a href="/services">Custom Website Design Services</a>
              </li>
              <li className="py-2 text-sm cursor-pointer font-semibold">
                <a href="/services">E-Commerce Solutions</a>
              </li>
            </ul>
          </div>
          <div className="pr-4">
            <h6 className="font-medium text-black">Inquires</h6>
            <ul>
              <li className="py-2 text-sm cursor-pointer font-semibold">
                <a href="/contact">eowens@gendesigntech.com</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="h-[1px] bg-white w-[100rem] ml-[-20rem]"></div>
        <p className='pt-4 text-white text-sm flex justify-center text-center'>All Rights Reserved © 2024 GenDesign Technologies LLC</p>
      </div>
      {/* footer bottom section with links */}
      <div></div>
      {/* footer bottom section with links end */}
    </div>
  );
}

export default Footer