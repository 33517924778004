import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'


const Herosection = ({title, subtitle, description, image}) => {
  return (
    <div className="font-poppins overflow-x-hidden">
      {/* component from tailgrids */}

      <div className="relative  pt-[120px] pb-[110px] lg:pt-[150px] bg-[#e0e0e0]">
        <div className="container mx-auto px-10">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4 lg:w-5/12">
              <div className="hero-content">
                <motion.div
                  variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 75 },
                  }}
                  initial="hidden"
                  animate="visible"
                  transition={{ duration: 0.5, delay: 0.25 }}
                >
                  <h1 className="text-black mb-3  font-bold leading-snug sm:text-[42px] lg:text-[40px] xl:text-[42px]">
                    {title} 
                    <br />
                    <span className="sm:text-8xl text-6xl">
                    {subtitle}
                    </span>
                  </h1>
                  <p className="text-body-color text-sm mb-8 max-w-[480px] sm:text-base">
                  {description}
                  </p>
                </motion.div>
                <motion.div
                  variants={{
                    visible: { opacity: 1, x: 0 },
                    hidden: { opacity: 0, x: -75 },
                  }}
                  initial="hidden"
                  animate="visible"
                  transition={{ duration: 0.5, delay: 0.25 }}
                >
                  <ul className="flex flex-wrap items-center">
                    <li>
                      <Link
                        to="#"
                        className="bg-customOrange inline-flex items-center justify-center rounded-lg py-4 px-6 text-center text-base font-normal text-white hover:bg-customOrange-dark sm:px-10 lg:px-8 xl:px-10"
                      >
                        Get Started
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="bg-customOrange/90 ml-6 inline-flex items-center justify-center rounded-lg py-4 px-6 text-center text-white font-normal hover:bg-customOrange sm:px-10 lg:px-8 xl:px-10"
                      >
                        Take a look
                      </Link>
                    </li>
                  </ul>
                </motion.div>
              </div>
            </div>
            <div className="hidden px-4 lg:block lg:w-1/12"></div>
            <div className="w-full  px-4 lg:w-6/12">
              <div className="lg:ml-auto lg:text-right">
                <motion.div
                  variants={{
                    visible: { opacity: 1, x: 0, filter: "blur(0px)" },
                    hidden: { opacity: 0, x: 75, filter: "blur(5px)" },
                  }}
                  initial="hidden"
                  animate="visible"
                  transition={{ duration: 0.5, delay: 0.25 }}
                  className="relative z-10 inline-block pt-11 lg:pt-0 mt-6"
                >
                  <img
                    src={image}
                    loading='lazy'
                    alt="hero"
                    className="max-w-full lg:ml-auto rounded-[6%] h-[300px] sm:h-[400px] lg:h-[600px]"
                  />
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* component from tailgrids end */}
    </div>
  );
}

export default Herosection