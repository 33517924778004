import React, { useEffect, useRef } from "react";
import icon1 from "../assests/1.png";
import icon2 from "../assests/2.png";
import icon3 from "../assests/3.png";
import icon4 from "../assests/4.png";
import icon5 from "../assests/5.png";
import icon6 from "../assests/6.png";
import image1 from "../../src/assests/feature1.jpg";
import image2 from "../../src/assests/feature2.jpg";
import { useAnimation, useInView, motion } from "framer-motion";

const Features = () => {
  const { innerWidth: width } = window;
  const ref = useRef(null);
  const isInView = useInView(ref);

  const mainControlls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControlls.start("visible");
    }
  }, [isInView, mainControlls]);
  return (
    <>
      <div className="max-w-screen overflow-x-hidden font-poppins">
        {/* features section */}
        {width < 631 ? (
          <>
            <section className=" text-black">
              <div className="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
                <div>
                  <h2 className="text-3xl font-bold tracking-tight text-center sm:text-5xl ">
                    Elevate Your Brand
                  </h2>
                  <p className="max-w-3xl mx-auto mt-4 text-xl text-center ">
                  Transform your online presence with our comprehensive web development services. Our team of expert developers is dedicated to delivering responsive, user-friendly, and visually appealing websites that meet your business needs.
                </p>
                </div>
                <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
                  <div>
                    <h3 className="text-2xl font-bold tracking-tight sm:text-3xl ">
                        Responsive Design
                    </h3>
                    <p className="mt-3 text-lg ">
                    Your website will look stunning on any device. From desktops to smartphones, we ensure a seamless user experience that keeps your audience engaged.
                    </p>
                    <div className="mt-12 space-y-12">
                      <motion.div
                        ref={ref}
                        variants={{
                          hidden: { opacity: 0, y: 75 },
                          visible: { opacity: 1, y: 0 },
                        }}
                        initial="hidden"
                        animate={mainControlls}
                        transition={{ duration: 0.5, delay: 0.15 }}
                        className="flex"
                      >
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center w-12 h-12 rounded-md dark:text-gray-900">
                            <img src={icon1}  alt="icon1" />
                          </div>
                        </div>
                        <div className="ml-4">
                          <h4 className="text-lg font-medium leading-6 mb-2 ">
                            Modern Technologies:
                        </h4>
                        <ul>
                             <li className="mb-2"><b>React:</b> For creating dynamic and interactive user interfaces.</li>
                             <li className="mb-2"><b>Node.js & Flask:</b> For robust server-side applications.</li>
                             <li className="mb-2"><b>MongoDB & MySQL:</b> For efficient and scalable database management.</li>
                        </ul>      
                                    
                        
                                                      
                          <p className="mt-2 ">
                          Every business is unique, and so are its website requirements. Our custom development solutions are tailored to match the specific needs of your business.
                          </p>
                        </div>
                      </motion.div>
                      <motion.div
                        ref={ref}
                        variants={{
                          hidden: { opacity: 0, y: 75 },
                          visible: { opacity: 1, y: 0 },
                        }}
                        initial="hidden"
                        animate={mainControlls}
                        transition={{ duration: 0.5, delay: 0.35 }}
                        className="flex"
                      >
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center w-12 h-12 rounded-md  dark:text-gray-900">
                            <img src={icon2} alt="icon2" />
                          </div>
                        </div>
                        <div className="ml-4">
                          <h4 className="text-lg font-medium leading-6 ">
                          Performance Optimization
                          </h4>
                          <p className="mt-2 ">
                          We optimize your website for faster load times and improved performance, ensuring a smooth and responsive user experience.
                          </p>
                        </div>
                      </motion.div>
                      <motion.div
                        ref={ref}
                        variants={{
                          hidden: { opacity: 0, y: 75 },
                          visible: { opacity: 1, y: 0 },
                        }}
                        initial="hidden"
                        animate={mainControlls}
                        transition={{ duration: 0.5, delay: 0.55 }}
                        className="flex"
                      >
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center w-12 h-12 rounded-md  dark:text-gray-900">
                            <img src={icon3}  alt="icon3" />
                          </div>
                        </div>
                        <div className="ml-4">
                          <h4 className="text-lg font-medium leading-6 ">
                          E-commerce Solutions
                          </h4>
                          <p className="mt-2 ">
                          Boost your sales with our tailored e-commerce websites. We build platforms that are not only attractive but also secure and easy to manage, providing your customers with a smooth shopping experience.
                          </p>
                        </div>
                      </motion.div>
                    </div>
                  </div>
                  <div ref={ref}>
                    <motion.div
                      variants={{
                        hidden: { opacity: 0, x: 75 },
                        visible: { opacity: 1, x: 0 },
                      }}
                      initial="hidden"
                      animate={mainControlls}
                      transition={{ duration: 0.5, delay: 0.25 }}
                      aria-hidden="true"
                      className="mt-10 lg:mt-0"
                    >
                      <img
                        src={image1}
                        alt="image1"
                        className="taos:translate-x-[200px] taos:opacity-0 hover:-translate-y-2 transition ease-in-out duration-300 hover:drop-shadow-xl mx-auto rounded-lg shadow-lg dark:bg-gray-500 "
                      />
                    </motion.div>
                  </div>
                </div>
                <div>
                  <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
                    <div className="lg:col-start-2">
                      <h3 className="text-2xl font-bold tracking-tight sm:text-3xl ">
                      Why Choose Us?
                      </h3>
                      <p className="mt-3 text-lg ">
                      We are passionate about delivering high-quality websites that not only meet your business needs but also exceed your expectations. With our expertise in modern technologies and commitment to providing exceptional support, we guarantee a seamless web development experience for every client.
                      </p>
                      <div className="mt-12 space-y-12">
                        <motion.div
                          ref={ref}
                          variants={{
                            hidden: { opacity: 0, y: -75 },
                            visible: { opacity: 1, y: 0 },
                          }}
                          initial="hidden"
                          animate={mainControlls}
                          transition={{ duration: 0.5, delay: 0.15 }}
                          className="flex"
                        >
                          <div className="flex-shrink-0">
                            <div className="flex items-center justify-center w-12 h-12 rounded-md  dark:text-gray-900">
                              <img src={icon4}  alt="icon4" />
                            </div>
                          </div>
                          <div className="ml-4">
                            <h4 className="text-lg font-medium leading-6 ">
                            Expertise
                            </h4>
                            <p className="mt-2 ">
                            Our developers are skilled in the latest web development technologies and best practices.
                            </p>
                          </div>
                        </motion.div>
                        <motion.div
                          ref={ref}
                          variants={{
                            hidden: { opacity: 0, y: -75 },
                            visible: { opacity: 1, y: 0 },
                          }}
                          initial="hidden"
                          animate={mainControlls}
                          transition={{ duration: 0.5, delay: 0.35 }}
                          className="flex"
                        >
                          <div className="flex-shrink-0">
                            <div className="flex items-center justify-center w-12 h-12 rounded-md  dark:text-gray-900">
                              <img src={icon5}  alt="icon5" />
                            </div>
                          </div>
                          <div className="ml-4">
                            <h4 className="text-lg font-medium leading-6 ">
                                Customization
                            </h4>
                            <p className="mt-2 ">
                              Tailored software solutions to address your unique
                              needs. Achieve sustainable growth by aligning with
                              your business objectives.
                            </p>
                          </div>
                        </motion.div>
                        <motion.div
                          ref={ref}
                          variants={{
                            hidden: { opacity: 0, y: -75 },
                            visible: { opacity: 1, y: 0 },
                          }}
                          initial="hidden"
                          animate={mainControlls}
                          transition={{ duration: 0.5, delay: 0.55 }}
                          className="flex"
                        >
                          <div className="flex-shrink-0">
                            <div className="flex items-center justify-center w-12 h-12 rounded-md  dark:text-gray-900">
                              <img src={icon6}  alt="icon6" />
                            </div>
                          </div>
                          <div className="ml-4">
                            <h4 className="text-lg font-medium leading-6 ">
                            Support
                            </h4>
                            <p className="mt-2 ">
                              From initial consultation to post-launch support, we're here for you every step of the way.
                            </p>
                          </div>
                        </motion.div>
                      </div>
                    </div>
                    <motion.div
                      ref={ref}
                      variants={{
                        hidden: { opacity: 0, x: -75 },
                        visible: { opacity: 1, x: 0 },
                      }}
                      initial="hidden"
                      animate={mainControlls}
                      transition={{ duration: 0.5, delay: 0.15 }}
                      className="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1"
                    >
                      <img
                        src={image2}
                        alt="image2"
                        className="taos:translate-x-[200px] taos:opacity-0 hover:-translate-y-2 transition ease-in-out duration-300 hover:drop-shadow-xl mx-auto rounded-lg shadow-lg dark:bg-gray-500"
                      />
                    </motion.div>
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <>
            <section className=" text-black">
              <div className="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
                <div>
                  <h2 className="text-3xl font-bold tracking-tight text-center sm:text-5xl ">
                    Elevate Your Brand
                  </h2>
                  <p className="max-w-3xl mx-auto mt-4 text-xl text-center ">
                  Transform your online presence with our comprehensive web development services. Our team of expert developers is dedicated to delivering responsive, user-friendly, and visually appealing websites that meet your business needs.
                </p>
                </div>
                <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
                  <div>
                    <h3 className="text-2xl font-bold tracking-tight sm:text-3xl ">
                        Responsive Design
                    </h3>
                    <p className="mt-3 text-lg ">
                    Your website will look stunning on any device. From desktops to smartphones, we ensure a seamless user experience that keeps your audience engaged.
                    </p>
                    <div className="mt-12 space-y-12">
                      <motion.div
                        ref={ref}
                        variants={{
                          hidden: { opacity: 0, y: 75 },
                          visible: { opacity: 1, y: 0 },
                        }}
                        initial="hidden"
                        animate={mainControlls}
                        transition={{ duration: 0.5, delay: 0.15 }}
                        className="flex"
                      >
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center w-12 h-12 rounded-full dark:text-gray-900">
                            <img src={icon1}  alt="icon1" />
                          </div>
                        </div>
                        <div className="ml-4">
                          <h4 className="text-lg font-medium leading-6 mb-2 ">
                            Modern Technologies:
                        </h4>
                        <ul>
                             <li className="mb-2"><b>React:</b> For creating dynamic and interactive user interfaces.</li>
                             <li className="mb-2"><b>Node.js & Flask:</b> For robust server-side applications.</li>
                             <li className="mb-2"><b>MongoDB & MySQL:</b> For efficient and scalable database management.</li>
                        </ul>      
                          <p className="mt-2 ">
                          Every business is unique, and so are its website requirements. Our custom development solutions are tailored to match the specific needs of your business.
                          </p>
                        </div>
                      </motion.div>
                      <motion.div
                        ref={ref}
                        variants={{
                          hidden: { opacity: 0, y: 75 },
                          visible: { opacity: 1, y: 0 },
                        }}
                        initial="hidden"
                        animate={mainControlls}
                        transition={{ duration: 0.5, delay: 0.35 }}
                        className="flex"
                      >
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center w-12 h-12 rounded-md  dark:text-gray-900">
                            <img src={icon2} alt="icon2" />
                          </div>
                        </div>
                        <div className="ml-4">
                          <h4 className="text-lg font-medium leading-6 ">
                          Performance Optimization
                          </h4>
                          <p className="mt-2 ">
                          We optimize your website for faster load times and improved performance, ensuring a smooth and responsive user experience.
                          </p>
                        </div>
                      </motion.div>
                      <motion.div
                        ref={ref}
                        variants={{
                          hidden: { opacity: 0, y: 75 },
                          visible: { opacity: 1, y: 0 },
                        }}
                        initial="hidden"
                        animate={mainControlls}
                        transition={{ duration: 0.5, delay: 0.55 }}
                        className="flex"
                      >
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center w-12 h-12 rounded-md  dark:text-gray-900">
                            <img src={icon3} alt="icon3" />
                          </div>
                        </div>
                        <div className="ml-4">
                          <h4 className="text-lg font-medium leading-6 ">
                          E-commerce Solutions
                          </h4>
                          <p className="mt-2 ">
                          Boost your sales with our tailored e-commerce websites. We build platforms that are not only attractive but also secure and easy to manage, providing your customers with a smooth shopping experience.
                          </p>
                        </div>
                      </motion.div>
                    </div>
                  </div>
                  <div ref={ref}>
                    <motion.div
                      variants={{
                        hidden: { opacity: 0, x: 75 },
                        visible: { opacity: 1, x: 0 },
                      }}
                      initial="hidden"
                      animate={mainControlls}
                      transition={{ duration: 0.5, delay: 0.25 }}
                      aria-hidden="true"
                      className="mt-10 lg:mt-0"
                    >
                      <img
                        src={image1}
                        alt="image1"
                        className="taos:translate-x-[200px] taos:opacity-0 hover:-translate-y-2 transition ease-in-out duration-300 hover:drop-shadow-xl mx-auto rounded-lg shadow-lg dark:bg-gray-500 h-[500px]"
                      />
                    </motion.div>
                  </div>
                </div>
                <div>
                  <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
                    <div className="lg:col-start-2">
                      <h3 className="text-2xl font-bold tracking-tight sm:text-3xl ">
                      Why Choose Us?
                      </h3>
                      <p className="mt-3 text-lg ">
                      We are passionate about delivering high-quality websites that not only meet your business needs but also exceed your expectations. With our expertise in modern technologies and commitment to providing exceptional support, we guarantee a seamless web development experience for every client.
                      </p>
                      <div className="mt-12 space-y-12">
                        <motion.div
                          ref={ref}
                          variants={{
                            hidden: { opacity: 0, y: -75 },
                            visible: { opacity: 1, y: 0 },
                          }}
                          initial="hidden"
                          animate={mainControlls}
                          transition={{ duration: 0.5, delay: 0.15 }}
                          className="flex"
                        >
                          <div className="flex-shrink-0">
                            <div className="flex items-center justify-center w-12 h-12 rounded-md  dark:text-gray-900">
                                <img src={icon4} alt="icon4" />
                              </div>
                          </div>
                          <div className="ml-4">
                            <h4 className="text-lg font-medium leading-6 ">
                            Expertise
                            </h4>
                            <p className="mt-2 ">
                            Our developers are skilled in the latest web development technologies and best practices.
                            </p>
                          </div>
                        </motion.div>
                        <motion.div
                          ref={ref}
                          variants={{
                            hidden: { opacity: 0, y: -75 },
                            visible: { opacity: 1, y: 0 },
                          }}
                          initial="hidden"
                          animate={mainControlls}
                          transition={{ duration: 0.5, delay: 0.35 }}
                          className="flex"
                        >
                          <div className="flex-shrink-0">
                            <div className="flex items-center justify-center w-12 h-12 rounded-md  dark:text-gray-900">
                              <img src={icon5}  alt="icon5" />
                            </div>
                          </div>
                          <div className="ml-4">
                            <h4 className="text-lg font-medium leading-6 ">
                                Customization
                            </h4>
                            <p className="mt-2 ">
                              Tailored software solutions to address your unique
                              needs. Achieve sustainable growth by aligning with
                              your business objectives.
                            </p>
                          </div>
                        </motion.div>
                        <motion.div
                          ref={ref}
                          variants={{
                            hidden: { opacity: 0, y: -75 },
                            visible: { opacity: 1, y: 0 },
                          }}
                          initial="hidden"
                          animate={mainControlls}
                          transition={{ duration: 0.5, delay: 0.55 }}
                          className="flex"
                        >
                          <div className="flex-shrink-0">
                            <div className="flex items-center justify-center w-12 h-12 rounded-md  dark:text-gray-900">
                              <img src={icon6} alt="icon6" />
                            </div>
                          </div>
                          <div className="ml-4">
                            <h4 className="text-lg font-medium leading-6 ">
                            Support
                            </h4>
                            <p className="mt-2 ">
                              From initial consultation to post-launch support, we're here for you every step of the way.
                            </p>
                          </div>
                        </motion.div>
                      </div>
                    </div>
                    <motion.div
                      ref={ref}
                      variants={{
                        hidden: { opacity: 0, x: -75 },
                        visible: { opacity: 1, x: 0 },
                      }}
                      initial="hidden"
                      animate={mainControlls}
                      transition={{ duration: 0.5, delay: 0.15 }}
                      className="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1"
                    >
                      <img
                        src={image2}
                        alt="image2"
                        className="taos:translate-x-[200px] taos:opacity-0 hover:-translate-y-2 transition ease-in-out duration-300 hover:drop-shadow-xl mx-auto rounded-lg shadow-lg dark:bg-gray-500"
                      />
                    </motion.div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}

        {/* end */}
      </div>
    </>
  );
};

export default Features;